<template>
  <div style="min-height: 16vw; text-align: center;">
    <img :alt="icon" :src="iconUrl" style="margin-top: 2vw; max-height: 8vw;">
    <div style="margin-top: 2vw; font-size: 2vw;">{{ title }}</div>
    <div>{{ description }}</div>
  </div>
</template>

<script>
export default {
  name: 'DescriptionCard',
  props: {
    icon: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, default: '' }
  },
  computed: {
    iconUrl () {
      return require(`@/assets/images/${this.icon}.svg`)
    }
  }
}
</script>
