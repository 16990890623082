<template>
  <a-layout>
    <a-layout-header style="background: #ffffff;">
      <img alt="logo" :src="require('@/assets/images/logo.svg')" />
      <div style="float: right;">
        <a-space>
          <a-button
            size="small"
            type="link"
            style="color: #000000; font-size: 16px;"
            @click="$router.push({ name: 'Login' })"
          >
            登陆
          </a-button>
          <a-button
            size="small"
            type="link"
            style="color: #000000; font-size: 16px;"
            @click="$router.push({ name: 'Register' })"
          >
            注册
          </a-button>
        </a-space>
      </div>
    </a-layout-header>
    <a-layout-content>
      <div class="banner">
        <div style="font-size: 4vw;">立维站点风险扫描系统</div>
        <div style="font-size: 2vw; margin-top: 2vw;">提前发现潜在风险</div>
      </div>
      <div style="min-height: 48vw; color: #000000;">
        <div style="margin-top: 4vw; text-align: center; font-size: 4vw;">常规漏洞检测</div>
        <div style="width: 8vw; margin: 2vw auto 0 auto; border-bottom: solid 0.4vw;"></div>
        <a-row style="margin-top: 4vw;">
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="find_in_page" title="SQL 注入检测"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="explore" title="XSS 检测"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="cloud_upload" title="文件上传检测"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="vpn_key" title="弱口令检测"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="navigate_next" title="命令检测"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="drag_indicator" title="......"></description-card>
          </a-col>
        </a-row>
      </div>
      <div style="min-height: 48vw; color: #000000;">
        <div style="margin-top: 4vw; text-align: center; font-size: 4vw;">应用漏洞检测</div>
        <div style="width: 8vw; margin: 2vw auto 0 auto; border-bottom: solid 0.4vw;"></div>
        <a-row style="margin-top: 4vw;">
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="thinkphp" title="ThinkPHP"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="struts2" title="Struts2"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="fastjson" title="FastJSON"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="drag_indicator" title="......"></description-card>
          </a-col>
        </a-row>
      </div>
      <div style="min-height: 48vw; background: #000000; color: #ffffff;">
        <div style="padding-top: 4vw; text-align: center; font-size: 4vw;">域名收集</div>
        <div style="width: 8vw; margin: 2vw auto 0 auto; border-bottom: solid 0.4vw;"></div>
        <a-row style="margin-top: 4vw;">
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="insert_drive_file" title="证书透明度查询"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="language" title="DNS 查询"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="search" title="搜索引擎查询"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="code" title="代码仓库查询"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="whatshot" title="子域名爆破"></description-card>
          </a-col>
          <a-col :xs="12" :md="8" style="margin-bottom: 2vw;">
            <description-card icon="drag_indicator" title="......"></description-card>
          </a-col>
        </a-row>
      </div>
      <div style="margin-top: 4vw; min-height: 48vw; color: #000000;">
        <div style="text-align: center; font-size: 4vw;">接入流程</div>
        <div style="width: 8vw; margin: 2vw auto 0 auto; border-bottom: solid 0.4vw;"></div>
        <div style="margin-top: 8vw; padding: 0 8vw;">
          <a-row>
            <a-col span="6">
              <description-card icon="check_circle" title="站点授权" style="border: dashed #1890ff 0.4vw;"></description-card>
            </a-col>
            <a-col span="3" style="min-height: 16vw; display: flex;">
              <div style="width: 100%; border-bottom: dashed #1890ff 0.4vw; align-self: center;"></div>
            </a-col>
            <a-col span="6">
              <description-card icon="autorenew" title="扫描检测" style="border: dashed #1890ff 0.4vw;"></description-card>
            </a-col>
            <a-col span="3" style="min-height: 16vw; display: flex;">
              <div style="width: 100%; border-bottom: dashed #1890ff 0.4vw; align-self: center;"></div>
            </a-col>
            <a-col span="6">
              <description-card icon="assessment" title="风险查看" style="border: dashed #1890ff 0.4vw;"></description-card>
            </a-col>
          </a-row>
        </div>
      </div>
      <div style="min-height: 24vw; background: #011d37; color: #ffffff;">
        <a-row style="padding: 0 50px;">
          <a-col :xs="12" :md="8" style="margin-top: 4vw;">
            <div style="font-size: 2vw;">联系方式</div>
            <div style="margin-top: 2vw; font-size: 1vw;">
              <span>邮箱: </span>
              <span>service@live400.com</span>
            </div>
            <div style="margin-top: 2vw; font-size: 1vw;">
              <span>电话: </span>
              <span>400-0445-755</span>
            </div>
            <div style="margin-top: 2vw; font-size: 1vw;">
              <span>地址: </span>
              <span>南京市江北新区星火路14号长峰大厦2号楼707室</span>
            </div>
          </a-col>
          <a-col :xs="12" :md="8">
            <div style="margin-top: 4vw; font-size: 2vw;">关注我们</div>
            <img alt="official-accounts" :src="require('@/assets/images/official_account.png')" style="margin-top: 2vw;" />
          </a-col>
        </a-row>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center; background: #001529; color: #ffffff;">
      <div style="font-size: 1vw;">
        <a-space>
          <div>© 2021 江苏立维互联科技有限公司</div>
          <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备15055366号</a>
        </a-space>
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import DescriptionCard from './modules/DescriptionCard'

export default {
  name: 'Index',
  components: { DescriptionCard }
}
</script>

<style scoped>
.banner {
  height: 48vw;
  padding-top: 16vw;
  color: white;
  text-align: center;
  background: url("~@/assets/images/index_background.png") no-repeat;
  background-size: 100%;
}
</style>
